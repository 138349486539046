/*
|--------------------------------------------------------------------------
| Tour Card
|--------------------------------------------------------------------------
*/

.tour-card-container {
  a {
    text-decoration: none;
  }

  a:hover {
    text-decoration: none;
  }

  .tour-card {
    position: relative;

    width: 100%;

    margin-bottom: 30px;

    background-color: #fff;
    border-radius: $border-radius;

    .tour-thumb {
      width: 100%;

      border-top-left-radius: $border-radius;
      border-top-right-radius: $border-radius;
    }

    .top-info-row {
      position: absolute;
      left: 15px;
      top: 10px;
      right: 15px;

      height: 25px;

      .tour-info {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex: 1;

        .badge {
          display: flex;
          align-items: center;
          justify-content: center;

          margin-right: 5px;
          padding: 5px 20px;

          background-color: $primary;
          border-radius: $border-radius;

          font-size: 0.75rem;
          color: #fff;
          text-decoration: none;
        }
      }
    }

    .bottom-section {
      position: relative;

      width: 100%;

      padding: 10px 15px 20px 15px;

      .location-row {
        position: absolute;
        top: -30px;
        left: 0px;
        right: 0px;

        display: flex;
        justify-content: space-between;
        align-items: center;

        height: 30px;

        padding-left: 15px;
        padding-right: 15px;

        background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0));

        .location {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          flex: 1;

          color: #fff;
          font-weight: $font-weight-bold;

          .marker {
            margin-right: 2px;

            width: 20px;
            height: 15px;
          }
        }
      }

      .locaction-row div:only-child {
        margin-left: auto;
      }

      h3 {
        height: 64px;

        color: $darktext;
        overflow: hidden;
      }

      .time-price-row {
        display: flex;
        justify-content: space-between;
        align-items: center;

        width: 100%;
        height: 25px;

        margin-bottom: 20px;

        .time {
          display: flex;
          justify-content: space-between;
          align-items: center;
          flex: 1;

          height: 20px;

          padding-left: 25px;

          background-image: url(/../../graphics/icons/time.svg);
          background-repeat: no-repeat;

          color: $darktext;
          font-size: 0.9rem;
          font-weight: $font-weight-medium;
        }

        .price {
          height: 24px;

          margin-left: auto;
          padding-right: 5px;

          font-size: 1.2rem;
          font-weight: $font-weight-extrabold;
          text-align: right;
          color: $darktext;
        }
      }
    }
  }
}