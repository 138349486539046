
///////////////////////////////////////////////////////
///////////////////-----Content-----///////////////////
///////////////////////////////////////////////////////

.wrapper {
  min-height: 100vh;
}

.pagecontent {
  background-color: #F5F5F5;
  padding-top: 30px;
}

.pagecontent-white {
  background-color: #FFF;
  padding-top: 30px;
}

.pagecontent-bottom-spacer {
  padding-bottom: 50px;
}
