
/*
|--------------------------------------------------------------------------
| Form Elements
|--------------------------------------------------------------------------
*/

.form-group {
  margin-bottom: 20px;
}


.col-form-label {
  margin-bottom: 0px;
  padding-bottom: 1px;

  color: $darktext;
  font-weight: $font-weight-medium;
}


.form-group input, .form-control {
  height: 44px;

  background-color: $tertiary;

  color: $darktext;
}


.form-group input, .form-control, ::placeholder {
  font-size: $font-size-base;
  font-weight: $font-weight-bold;
  color: $darktext;
}


.form-group select {
  height: 44px;

  padding-left: 20px;

  background-color: $tertiary;

  font-weight: $font-weight-bold;
  color: $darktext;
  line-height: 1.5;
}


.custom-select {
  height: 44px;

  padding-left: 20px;

  background-color: $tertiary;

  font-weight: $font-weight-bold;
  color: $darktext;
  line-height: 1.5;
}


.form-group textarea {
  background-color: $tertiary;

  color: $darktext;
}
